import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/reset.css";
import ProtectedRoute from "./components/utilities/protectedRoute";

// pages
import Home from "./pages/Home";
import Signin from "./pages/SignIn";
import OperatorAdminSignin from "./pages/operatorAdminSignIn";
import Signup from "./pages/SignUp";
import Users from "./pages/users";
import Account from "./pages/account";
import RaffleTickets from "./pages/raffleTickets";
import PartnerStores from "./pages/PartnerStores";
import AllProducts from "./pages/AllProducts";
import PartnerStoresProducts from "./pages/PartnerStoreProducts";
import MerchantCategoryMenu from "./pages/merchantCategoryMenu";
import MerchantMenu from "./pages/merchantMenu";
import OperatorPerformace from "./pages/operatorPerformace";
import Notification from "./pages/notification";
import Bookings from "./pages/bookings";
import BookingAvailableRiders from "./pages/bookingAvailableRiders";
import OperatorPayables from "./pages/operatorPayables";
import PriceApproval from "./pages/priceApproval";
import Customer from "./pages/customers";
import AppearningWithdrawalReq from "./pages/appEarningWithdrawal";
import OperatorWithdrawalRequest from "./pages/operatorWithdrawalRequest";
import MerchantWithdrawalRequest from "./pages/merchantWithdrawalRequest";
import AccountsReceivable from "./pages/accountsReceivable";
import OperatorApproval from "./pages/operatorApproval";
import FixRates from "./pages/fixRates";
import MerchantTags from "./pages/merchantTags";
import DeleteAccount from "./pages/deleteAccount";
import CreateAccount from "./pages/createAdminAccounts";
import GroupedAreas from "./pages/groupedAreas";
import FranchiseePayables from "./pages/franchiseePayables";
import PayablesStatus from "./pages/payablesStatus";
import ApproveFranchiseePayables from "./pages/ApprovedFranchiseePayables";
import CreateCoupons from "./pages/create_coupons";
import partnerPushNotif from "./pages/partnerPushNotif";
import PartnerCoupone from "./pages/partnerCoupone";
import GetStatedScreen from "./pages/getStarted";
import SucceedingDistanceRate from "./pages/succeedingDistanceRate";
import PayablesMonitoring from "./pages/payablesMonitoring";

import { useEffect, useMemo } from "react";
import DeliveryFeeSettings from "./pages/deliveryFeeSettings";
import PartnerDashBoard from "./pages/partnerDashBoard";
import AdminDashBoard from "./pages/adminDashBoard";
import RiderInMyAreaPage from "./pages/RiderInMyArea";
import ReferredRiderPage from "./pages/referredRider";
import RiderPackageAvailed from "./pages/riderPackageAvailed";

import NationalMerchants from "./pages/nationalMerchants";
import NationalMerchantMenu from "./pages/nationalMerchantsMenu";
import NationalMerchantMenuCategories from "./pages/nationalMerchantMenuCategories";

import MerchantInMyAreaPage from "./pages/merchantInMyArea";
import referredLocalMerchantsPage from "./pages/referredLocalMerchants";
import MerchantsForApproval from "./pages/localMerchantsApproval";
import MerchantsMenusForApproval from "./pages/localMerchantsMenusApproval";
import ReferredCustomers from "./pages/referredCustomers";
import NewRiders from "./pages/newRiders";
import ValidateReportedRiders from "./pages/validateReportedRiders";
import PartnerFinance from "./pages/partnerFinance";
import TransferFund from "./pages/transferFund";

import RiderDocuments from "./pages/riderDocuments";
import referredAffiliates from "./pages/referredAffiliates";
import AreaAffiliates from "./pages/areaAffiliates";

import RiderPackageMonitoring from "./pages/riderPackageMonitoring";

import ValidateReportedBookings from "./pages/validateReportedBookings";
import SubOperatorReferral from "./pages/subOperatorReferral";
import NewAccount from "./pages/newAccount";

import ReferredOperator from "./pages/referredOperator";
import ReferredSubOperator from "./pages/referredSubOperator";
import AreaSubOperator from "./pages/areaSubOperator";
import SubOperatorTraining from "./pages/subOperatorTrainings";
const App = () => {
  const { userInfo: userAccountAuth } = useSelector(
    (state) => state.userLoginReducer
  );

  const authInfo = useMemo(() => {
    if (userAccountAuth) {
      return { account: userAccountAuth, isAuth: true };
    } else {
      return { account: {}, isAuth: false };
    }
  }, [userAccountAuth]);

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://app.softgpt.io/webchat/plugin.js?v=5";
    script1.async = true;
    document.body.appendChild(script1);

    script1.onload = () => {
      const script2 = document.createElement("script");
      script2.text = `
       ktt10.setup({id:"55vUpPBLbcVeHPtCD5Fo",accountId:"1082735",color:"#36D6B5"})
      `;
      document.body.appendChild(script2);
    };

    script1.onerror = () => {
      console.error("Failed to load the script.");
    };
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/partner-signin" component={OperatorAdminSignin} />
        <Route path="/signin" component={Signin} />
        <Route path="/signup" component={Signup} />
        <Route path="/dory/account/delete" component={DeleteAccount} />
        <Route path="/referral" component={SubOperatorReferral} />
        <Route path="/newAccount" component={NewAccount} />
        <ProtectedRoute path="/home" component={Home} authInfo={authInfo} />
        <ProtectedRoute path="/users" component={Users} authInfo={authInfo} />

        {/* */}
        <ProtectedRoute
          path="/sub-operator-training"
          component={SubOperatorTraining}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/referred-operator"
          component={ReferredOperator}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/referred-suboperator"
          component={ReferredSubOperator}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/area-suboperator"
          component={AreaSubOperator}
          authInfo={authInfo}
        />

        <ProtectedRoute
          path="/partnerstores"
          component={PartnerStores}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/reportedbookings"
          component={ValidateReportedBookings}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/coupons"
          component={CreateCoupons}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/raffletickets"
          component={RaffleTickets}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/allproducts"
          component={AllProducts}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/merchantCategoryMenu/:merchantId/:merchantName"
          component={MerchantCategoryMenu}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/merchantMenu/:merchantId/:merchantName/:merchantTypeId"
          component={MerchantMenu}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/partnerstoresproducts/:storeId/:city/:province"
          component={PartnerStoresProducts}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/operatorperformace"
          component={OperatorPerformace}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/bookings"
          component={Bookings}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/booking-available-riders/:deliveryAddressId"
          component={BookingAvailableRiders}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/account"
          component={Account}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/notification"
          component={Notification}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/operator-payables"
          component={OperatorPayables}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/operator-approval"
          component={OperatorApproval}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/price-approval"
          component={PriceApproval}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/customer-list"
          component={Customer}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/fix-rates"
          component={FixRates}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/operator-withdrawal-list"
          component={OperatorWithdrawalRequest}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/merchant-withdrawal-list"
          component={MerchantWithdrawalRequest}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/app-earning-withdrawal-list"
          component={AppearningWithdrawalReq}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/accounts-receivable"
          component={AccountsReceivable}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/merchant-tags"
          component={MerchantTags}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/create-account"
          component={CreateAccount}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/grouped-areas"
          component={GroupedAreas}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/franchisee-payables"
          component={FranchiseePayables}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/payables-status"
          component={PayablesStatus}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/approved-partner-payables/:partnerId"
          component={ApproveFranchiseePayables}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/partner-notification"
          component={partnerPushNotif}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/partner-coupon"
          component={PartnerCoupone}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/getstarted"
          component={GetStatedScreen}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/succeeding-rate"
          component={SucceedingDistanceRate}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/delivery-fee-settings"
          component={DeliveryFeeSettings}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/payables-monitoring"
          component={PayablesMonitoring}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/partner-dashboard"
          component={PartnerDashBoard}
          authInfo={authInfo}
        />

        <ProtectedRoute
          path="/admin-dashboard"
          component={AdminDashBoard}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/rider-my-area"
          component={RiderInMyAreaPage}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/referred-rider"
          component={ReferredRiderPage}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/rider-availed-package"
          component={RiderPackageAvailed}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/national-merchants"
          component={NationalMerchants}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/national-merchant-menu/:id/:name"
          component={NationalMerchantMenu}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/national-merchant-categories/:id/:name"
          component={NationalMerchantMenuCategories}
          authInfo={authInfo}
        />

        {/* <ProtectedRoute
          path="/local-merchant-in-my-area"
          component={MerchantInMyAreaPage}
          authInfo={authInfo}
        /> */}
        <ProtectedRoute
          path="/referred-local-merchant"
          component={referredLocalMerchantsPage}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/merchants-for-approval"
          component={MerchantsForApproval}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/merchants-menu-for-approval"
          component={MerchantsMenusForApproval}
          authInfo={authInfo}
        />

        <ProtectedRoute
          path="/referred-customer"
          component={ReferredCustomers}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/new-riders"
          component={NewRiders}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/validate-reported-riders"
          component={ValidateReportedRiders}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/partner-finance"
          component={PartnerFinance}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/fund-transfer"
          component={TransferFund}
          authInfo={authInfo}
        />

        <ProtectedRoute
          path="/riders-documents"
          component={RiderDocuments}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/referred-affiliates"
          component={referredAffiliates}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/riders-package-monitoring"
          component={RiderPackageMonitoring}
          authInfo={authInfo}
        />
        <ProtectedRoute
          path="/area-affiliates"
          component={AreaAffiliates}
          authInfo={authInfo}
        />
      </Switch>
    </Router>
  );
};

export default App;
